import { graphql, useStaticQuery } from 'gatsby';
import AWSIcon from 'svgs/single-case-study/aws.svg';
import NodeJsIcon from 'svgs/single-case-study/node-js.svg';
import ReactJsIcon from 'svgs/single-case-study/react-js.svg';
import UxUiDesign from 'svgs/single-case-study/ux-ui-design.svg';
import WebDevelopmentIcon from 'svgs/single-case-study/web-development.svg';

import { contentResolver } from '../utilities';

const content = contentResolver({
    project: 'nftReality',
    brandColor: '--nft-reality-case-study',
    brandColorGradient: {
        start: '--secondary-color',
        stop: '--nft-reality-case-study-gradient-stop',
    },
    scopeOfWork: [
        {
            title: 'scopeOfWork.webDevelopment',
            Icon: WebDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.nodeJs',
            Icon: NodeJsIcon,
        },
        {
            title: 'scopeOfWork.reactJs',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiDesign,
        },
        {
            title: 'scopeOfWork.awsCloud',
            Icon: AWSIcon,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
        colors: [
            '--nft-reality-case-study',
            '--nft-reality-case-study-green',
            '--nft-reality-case-study-blue',
        ],
    },
    paragraphsCounters: {
        header: 1,
        about: 1,
        challenge: 1,
        mainGoals: 1,
        solutions: 1,
        summary: 1,
    },
    caseStudies: ['heyway', '4Tipsters', 'letempick'],
    caseStudiesSubtitle: 'case-studies.subtitle',
});

export const useNFTReality = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: {
                        eq: "single-case-study/nft-reality/laptop.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: {
                        eq: "single-case-study/nft-reality/about.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/nft-reality/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/nft-reality/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/nft-reality/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/nft-reality/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
